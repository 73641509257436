<template>
  <div>
    <div class="text-center lead">
      Screen coming soon
    </div>
  </div>
</template>

<script>
export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}coming-soon`
};
</script>

<style lang="scss" scoped></style>
